const colors = {
blue:{
    blue1: '#fbfdff',
    blue2: '#f5faff',
    blue3: '#edf6ff',
    blue4: '#e1f0ff',
    blue5: '#cee7fe',
    blue6: '#b7d9f8',
    blue7: '#96c7f2',
    blue8: '#5eb0ef',
    blue9: '#0091ff',
    blue10: '#0880ea',
    blue11: '#0b68cb',
    blue12: '#113264',
  },
  blueDark: {
    blue1: '#0f1720',
    blue2: '#0f1b2d',
    blue3: '#11253f',
    blue4: '#122b4c',
    blue5: '#12325a',
    blue6: '#123d6f',
    blue7: '#0f5096',
    blue8: '#1276e2',
    blue9: '#0091ff',
    blue10: '#3cabff',
    blue11: '#6bc1ff',
    blue12: '#c2e5ff',
  },
  cyan: {
    cyan1: '#fafdfe',
    cyan2: '#f2fcfd',
    cyan3: '#e7f9fb',
    cyan4: '#d8f3f6',
    cyan5: '#c4eaef',
    cyan6: '#aadee6',
    cyan7: '#84cdda',
    cyan8: '#3db9cf',
    cyan9: '#05a2c2',
    cyan10: '#0894b3',
    cyan11: '#0c7792',
    cyan12: '#0d3c48',
  },
  cyanDark: {
    cyan1: '#07191d',
    cyan2: '#0b1d22',
    cyan3: '#0f272e',
    cyan4: '#112f37',
    cyan5: '#143741',
    cyan6: '#17444f',
    cyan7: '#1d5b6a',
    cyan8: '#28879f',
    cyan9: '#05a2c2',
    cyan10: '#13b7d8',
    cyan11: '#20d0f3',
    cyan12: '#b6ecf7',
  },
  green:{
    green1: '#fbfefc',
    green2: '#f2fcf5',
    green3: '#e9f9ee',
    green4: '#ddf3e4',
    green5: '#ccebd7',
    green6: '#b4dfc4',
    green7: '#92ceac',
    green8: '#5bb98c',
    green9: '#30a46c',
    green10: '#299764',
    green11: '#18794e',
    green12: '#193b2d',
  },
  greenDark: {
    green1: '#0d1912',
    green2: '#0f1d17',
    green3: '#12281f',
    green4: '#143125',
    green5: '#173a2a',
    green6: '#194633',
    green7: '#1f5e41',
    green8: '#2c8c5e',
    green9: '#30a46c',
    green10: '#35b979',
    green11: '#3dd68c',
    green12: '#b1f1cb',
  },
  grass: {
    grass1: '#fbfefb',
    grass2: '#f3fcf3',
    grass3: '#ebf9eb',
    grass4: '#dff3df',
    grass5: '#ceebcf',
    grass6: '#b7dfba',
    grass7: '#97cf9c',
    grass8: '#65ba75',
    grass9: '#46a758',
    grass10: '#3d9a50',
    grass11: '#297c3b',
    grass12: '#203c25',
  },
  grassDark: {
    grass1: '#0d1912',
    grass2: '#131d16',
    grass3: '#18281d',
    grass4: '#1b3021',
    grass5: '#1e3926',
    grass6: '#24452d',
    grass7: '#2d5d39',
    grass8: '#428a4f',
    grass9: '#46a758',
    grass10: '#5cbc6e',
    grass11: '#71d083',
    grass12: '#c2f0c2',
  },
  orange: {
    orange1: '#fefcfb',
    orange2: '#fff8f4',
    orange3: '#ffedd5',
    orange4: '#ffe0bb',
    orange5: '#ffd3a4',
    orange6: '#ffc291',
    orange7: '#ffaa7d',
    orange8: '#ed8a5c',
    orange9: '#f76808',
    orange10: '#ed5f00',
    orange11: '#99543a',
    orange12: '#582d1d',
  },
  orangeDark: {
    orange1: '#1f1206',
    orange2: '#271504',
    orange3: '#341c0a',
    orange4: '#3f220d',
    orange5: '#4b2910',
    orange6: '#5d3213',
    orange7: '#7e4318',
    orange8: '#c36522',
    orange9: '#f76808',
    orange10: '#ff802b',
    orange11: '#ffa366',
    orange12: '#ffe0c2',
  },
  tomato: {
    tomato1: '#fffcfc',
    tomato2: '#fff8f7',
    tomato3: '#fff0ee',
    tomato4: '#ffe6e2',
    tomato5: '#fdd8d3',
    tomato6: '#fac7be',
    tomato7: '#f3b0a2',
    tomato8: '#ea9280',
    tomato9: '#e54d2e',
    tomato10: '#d84224',
    tomato11: '#c33113',
    tomato12: '#5c271f',
  },
  tomatoDark: {
    tomato1: '#1d1412',
    tomato2: '#291612',
    tomato3: '#3b1a14',
    tomato4: '#471d16',
    tomato5: '#532017',
    tomato6: '#652318',
    tomato7: '#862919',
    tomato8: '#ca3416',
    tomato9: '#e54d2e',
    tomato10: '#f46d52',
    tomato11: '#ff8870',
    tomato12: '#fbd3cb',
  },
  red: {
    red1: '#fffcfc',
    red2: '#fff7f7',
    red3: '#ffefef',
    red4: '#ffe5e5',
    red5: '#fdd8d8',
    red6: '#f9c6c6',
    red7: '#f3aeaf',
    red8: '#eb9091',
    red9: '#e5484d',
    red10: '#d93d42',
    red11: '#c62a2f',
    red12: '#641723',
  },
  redDark: {
    red1: '#1f1315',
    red2: '#291618',
    red3: '#3b191d',
    red4: '#481a20',
    red5: '#551c22',
    red6: '#691d25',
    red7: '#8c1d28',
    red8: '#d21e24',
    red9: '#e5484d',
    red10: '#f26669',
    red11: '#ff8589',
    red12: '#ffd1d9',
  },
  crimson: {
    crimson1: '#fffcfd',
    crimson2: '#fff7fb',
    crimson3: '#feeff6',
    crimson4: '#fce5f0',
    crimson5: '#f9d8e7',
    crimson6: '#f4c6db',
    crimson7: '#edadc8',
    crimson8: '#e58fb1',
    crimson9: '#e93d82',
    crimson10: '#dc3175',
    crimson11: '#cb1d63',
    crimson12: '#621639',
  },
  crimsonDark: {
    crimson1: '#1d1418',
    crimson2: '#29151d',
    crimson3: '#391826',
    crimson4: '#441a2b',
    crimson5: '#511c31',
    crimson6: '#641e3a',
    crimson7: '#881f49',
    crimson8: '#cf1761',
    crimson9: '#e93d82',
    crimson10: '#f46396',
    crimson11: '#ff85ab',
    crimson12: '#fdd3e8',
  },
  pink: {
    pink1: '#fffcfe',
    pink2: '#fff7fc',
    pink3: '#feeef8',
    pink4: '#fce5f3',
    pink5: '#f9d8ec',
    pink6: '#f3c6e2',
    pink7: '#ecadd4',
    pink8: '#e38ec3',
    pink9: '#d6409f',
    pink10: '#cd3093',
    pink11: '#c41c87',
    pink12: '#651249',
  },
  pinkDark: {
    pink1: '#1f121b',
    pink2: '#291523',
    pink3: '#37192e',
    pink4: '#411c35',
    pink5: '#4b1f3d',
    pink6: '#5d224a',
    pink7: '#7c2860',
    pink8: '#bc2f88',
    pink9: '#d6409f',
    pink10: '#e45eaf',
    pink11: '#f986c9',
    pink12: '#fdd1ea',
  },
  plum: {
    plum1: '#fefcff',
    plum2: '#fff8ff',
    plum3: '#fceffc',
    plum4: '#f9e5f9',
    plum5: '#f3d9f4',
    plum6: '#ebc8ed',
    plum7: '#dfafe3',
    plum8: '#cf91d8',
    plum9: '#ab4aba',
    plum10: '#a43cb4',
    plum11: '#9c2bad',
    plum12: '#53195d',
  },
  plumDark: {
    plum1: '#1d131d',
    plum2: '#261526',
    plum3: '#341b34',
    plum4: '#3d1f3e',
    plum5: '#462348',
    plum6: '#542957',
    plum7: '#6e3473',
    plum8: '#9e49ab',
    plum9: '#ab4aba',
    plum10: '#be66cd',
    plum11: '#dc8eeb',
    plum12: '#f4d4f4',
  },
  purple: {
    purple1: '#fefcfe',
    purple2: '#fdfaff',
    purple3: '#f9f1fe',
    purple4: '#f3e7fc',
    purple5: '#eddbf9',
    purple6: '#e3ccf4',
    purple7: '#d3b4ed',
    purple8: '#be93e4',
    purple9: '#8e4ec6',
    purple10: '#8445bc',
    purple11: '#793aaf',
    purple12: '#402060',
  },
  purpleDark: {
    purple1: '#1b141d',
    purple2: '#231528',
    purple3: '#311c3a',
    purple4: '#3a2046',
    purple5: '#422451',
    purple6: '#4d2a61',
    purple7: '#61357e',
    purple8: '#8349b6',
    purple9: '#8e4ec6',
    purple10: '#9e63d5',
    purple11: '#c794f9',
    purple12: '#ecd9fa',
  },
  violet: {
    violet1: '#fdfcfe',
    violet2: '#fbfaff',
    violet3: '#f5f2ff',
    violet4: '#ede9fe',
    violet5: '#e4defc',
    violet6: '#d7cff9',
    violet7: '#c4b8f3',
    violet8: '#aa99ec',
    violet9: '#6e56cf',
    violet10: '#644fc1',
    violet11: '#5746af',
    violet12: '#2f265f',
  },
  violetDark: {
    violet1: '#17151f',
    violet2: '#1c172b',
    violet3: '#271f3f',
    violet4: '#2d254c',
    violet5: '#342a58',
    violet6: '#3d316a',
    violet7: '#4c3e89',
    violet8: '#6654c0',
    violet9: '#6e56cf',
    violet10: '#836add',
    violet11: '#b399ff',
    violet12: '#e2ddfe',
  },
  indigo: {
    indigo1: '#fdfdfe',
    indigo2: '#f8faff',
    indigo3: '#f0f4ff',
    indigo4: '#e6edfe',
    indigo5: '#d9e2fc',
    indigo6: '#c6d4f9',
    indigo7: '#aec0f5',
    indigo8: '#8da4ef',
    indigo9: '#3e63dd',
    indigo10: '#3a5ccc',
    indigo11: '#3451b2',
    indigo12: '#1f2d5c',
  },
  indigoDark: {
    indigo1: '#131620',
    indigo2: '#15192d',
    indigo3: '#1a2242',
    indigo4: '#1e284f',
    indigo5: '#202d5c',
    indigo6: '#24366e',
    indigo7: '#2c438f',
    indigo8: '#3b5dce',
    indigo9: '#3e63dd',
    indigo10: '#5c73e7',
    indigo11: '#99a2ff',
    indigo12: '#dddffe',
  },
  teal: {
    teal1: '#fafefd',
    teal2: '#f1fcfa',
    teal3: '#e7f9f5',
    teal4: '#d9f3ee',
    teal5: '#c7ebe5',
    teal6: '#afdfd7',
    teal7: '#8dcec3',
    teal8: '#53b9ab',
    teal9: '#12a594',
    teal10: '#0e9888',
    teal11: '#067a6f',
    teal12: '#0d3d38',
  },
  tealDark: {
    teal1: '#091a16',
    teal2: '#091f1a',
    teal3: '#0d2923',
    teal4: '#0f312b',
    teal5: '#123a32',
    teal6: '#16463d',
    teal7: '#1b5e54',
    teal8: '#238b7f',
    teal9: '#12a594',
    teal10: '#0abba4',
    teal11: '#0bd8b6',
    teal12: '#adf0dd',
  },
  brown: {
    brown1: '#fefdfc',
    brown2: '#fcf9f6',
    brown3: '#f8f1ea',
    brown4: '#f4e9dd',
    brown5: '#efddcc',
    brown6: '#e8cdb5',
    brown7: '#ddb896',
    brown8: '#d09e72',
    brown9: '#ad7f58',
    brown10: '#9e7352',
    brown11: '#815e46',
    brown12: '#3e332e',
  },
  brownDark: {
    brown1: '#191513',
    brown2: '#1e1a17',
    brown3: '#29221d',
    brown4: '#312821',
    brown5: '#3b2f26',
    brown6: '#48392d',
    brown7: '#614c3a',
    brown8: '#937153',
    brown9: '#ad7f58',
    brown10: '#bd926c',
    brown11: '#dbb594',
    brown12: '#f2e1ca',
  },
  gold: {
    gold1: '#fdfdfc',
    gold2: '#fbf9f2',
    gold3: '#f5f2e9',
    gold4: '#eeeadd',
    gold5: '#e5dfd0',
    gold6: '#dad1bd',
    gold7: '#cbbda4',
    gold8: '#b8a383',
    gold9: '#978365',
    gold10: '#89775c',
    gold11: '#71624b',
    gold12: '#3b352b',
  },
  goldDark: {
    gold1: '#171613',
    gold2: '#1b1a17',
    gold3: '#24231e',
    gold4: '#2c2a24',
    gold5: '#34312a',
    gold6: '#413c33',
    gold7: '#564f42',
    gold8: '#847662',
    gold9: '#978365',
    gold10: '#a99679',
    gold11: '#cbb99f',
    gold12: '#e8e2d9',
  },
  gray: {
    gray1: '#fcfcfc',
    gray2: '#f9f9f9',
    gray3: '#f1f1f1',
    gray4: '#ebebeb',
    gray5: '#e4e4e4',
    gray6: '#dddddd',
    gray7: '#d4d4d4',
    gray8: '#bbbbbb',
    gray9: '#8d8d8d',
    gray10: '#808080',
    gray11: '#646464',
    gray12: '#202020',
  },
  grayDark: {
    gray1: '#181818',
    gray2: '#1b1b1b',
    gray3: '#282828',
    gray4: '#303030',
    gray5: '#373737',
    gray6: '#3f3f3f',
    gray7: '#4a4a4a',
    gray8: '#606060',
    gray9: '#6e6e6e',
    gray10: '#818181',
    gray11: '#b1b1b1',
    gray12: '#eeeeee',
  },
  bronze: {
    bronze1: '#fdfcfc',
    bronze2: '#fdf8f6',
    bronze3: '#f8f1ee',
    bronze4: '#f2e8e4',
    bronze5: '#eaddd7',
    bronze6: '#e0cec7',
    bronze7: '#d1b9b0',
    bronze8: '#bfa094',
    bronze9: '#a18072',
    bronze10: '#947467',
    bronze11: '#7d5e54',
    bronze12: '#43302b',
  },
  bronzeDark: {
    bronze1: '#191514',
    bronze2: '#1c1918',
    bronze3: '#272220',
    bronze4: '#302926',
    bronze5: '#382f2c',
    bronze6: '#463a35',
    bronze7: '#5d4c45',
    bronze8: '#8d7266',
    bronze9: '#a18072',
    bronze10: '#b39283',
    bronze11: '#d4b3a5',
    bronze12: '#ede0d9',
  },
  jade: {
    jade1: '#fbfefd',
    jade2: '#effdf6',
    jade3: '#e4faef',
    jade4: '#d7f4e6',
    jade5: '#c6ecdb',
    jade6: '#b0e0cc',
    jade7: '#8fcfb9',
    jade8: '#56ba9f',
    jade9: '#29a383',
    jade10: '#259678',
    jade11: '#1a7a5e',
    jade12: '#1d3b31',
  },
  jadeDark: {
    jade1: '#081911',
    jade2: '#0b1f16',
    jade3: '#0f291e',
    jade4: '#123124',
    jade5: '#143a2b',
    jade6: '#184635',
    jade7: '#1e5e48',
    jade8: '#238b6f',
    jade9: '#29a383',
    jade10: '#25ba92',
    jade11: '#1fd8a4',
    jade12: '#adf0d4',
  }
} 

export default colors
import { useState, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { settingsData } from '../model/components/settingsData'

import { Line } from '../components/generic/Layout'

// Importing sub-Components
import Sidebar from '../components/sidebar/Sidebar'
import Header from '../components/header/Header'
import Loader from '../components/generic/Loader'
import Reload from '../components/header/Reload'

//Import Syling Components
import { Container } from '../components/generic/Layout'

import useTranslation from '../hooks/useTranslation'
import { useSnapshot } from 'valtio'

import useProfile from '../hooks/useProfile'
import useAuth from '../hooks/useAuth'
import useSettings from '../hooks/useSettings'
import usePwaUpdate from '../hooks/usePwaUpdate'

const PageContent = styled.div`
  min-width: 0;
//  padding-left: 0.5rem;
  color: ${({ theme }) => theme.font.color};
  background: ${({ theme }) => theme.area.background};
  flex: 1 0 0%;
  `

const AppArea = styled(Line)`
  min-height: 100vh;
`

const TopNotification = styled.div`
  background: ${({ theme }) => theme.btn.yellow};
  padding-block: 0.5rem;
`

const Layout = () => {
  //console.log('Rendered: Layout')

  const [isLoading, setIsLoading] = useState(true)
  const { tState, loadTrans } = useTranslation()
  const { pState } = useProfile()

  const snap = useSnapshot(tState)
  const { initializeProfile } = useProfile()
  const profileSnap = useSnapshot(pState)

  const {initializeSettings} = useSettings()

  // Initialize PWA update detection
  usePwaUpdate()

  //Setting up a few things
  const { Auth } = useAuth()
  let location = useLocation()
  let removeSidebar = false

  //Remove Sidebar if slug is "org" to hide navigation
  if (location.pathname === '/org') { removeSidebar = true }

  const loadTranslations = async () => {
    await loadTrans()
    setIsLoading(false)
  }

  const loadSettings = async () => {
    const path = location.pathname.replace(/^\//, "");
    const settings = `${path}Settings`
    if (settingsData[settings] ) {
      //console.log('<Layout>: loading this setting', settings) 
      await initializeSettings(settings)
    }
  }

  //This initializes the profile on first load of the Layout
  useEffect(
    () => { initializeProfile() }, [Auth.orgId])

  useEffect(
    () => {     
      loadSettings()
    //  console.log('LOCATION: ', location.pathname)
    }, [location.pathname])

  // Reload the translations when language changes.
  useEffect(() => {
    loadTranslations()
  }, [snap.currentLang])

  if (isLoading) {
    return (<Loader />)
  } else
    return (
      <Container id='Layout-Container'>
        <AppArea id='AppArea'>
          {removeSidebar ? <></> :
            <Sidebar />}
          <PageContent id='Page-Content'>
            {profileSnap.newVersion && <TopNotification><Reload /></TopNotification>}
            {removeSidebar ? <></> :
                <Header />
              }
            <main>
              <Outlet key={`${location.pathname}/${snap.currentLang}`} />
            </main>
          </PageContent>
        </AppArea>
      </Container>
    )
}

export default Layout

import { createContext, useState } from "react"

import {AuthProviderProps} from '../types/context/context'

const AuthContext = createContext({});

export const AuthProvider = (props: AuthProviderProps) => {
    const [Auth, setAuth] = useState({});

    return(
        <AuthContext.Provider value={ {Auth, setAuth} }>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button } from '../components/generic/Core'
import LangSwitcher from '../components/header/LangSwitcher'
import useTranslation from '../hooks/useTranslation'
import { useSearchParams } from 'react-router-dom'

const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background: ${ ({theme}) => theme.area.background };
`


const Wrapper = styled.section`
  display: block;
  background-image: 'url("/vite.svg")';
  background-size: 'cover';
  background-position: 'center';
  flex-direction: 'column';
  align-items: 'center';
  justify-content: 'center';
  padding: '20px';
  color: 'white';
`

const FlexWrapper = styled.section`
  justify-content: center;
  align-items: center;
  padding: '20px';
  color: 'white';
  display: flex;
  height: 100%;
`

const HeaderWrapper = styled.section`
  justify-content: end;
  align-items: end;
  padding: '20px';
  margin-bottom: 10px;
  margin-top: 10px;
  color: 'white';
  display: flex;
`
const ContentWrapper = styled.section`
  display: flex;
  color: ${({theme})=> theme.font.color};
  margin:1rem;
`
const BottomSection = styled.section`
height: 40vh; 
`

const StyledLink = styled(Link)`
    color: #fff;
    text-decoration: solid;
    &:hover{
      color:#eee;
    }
`


function LoggedOut() {
  const { t, tState } = useTranslation()
  const [searchParams] = useSearchParams()
  const lang = searchParams.get('lang')


  return (
    <Background>
      <FlexWrapper id='LoggedOutPage'>
        <Wrapper>
          <HeaderWrapper>
            <LangSwitcher />
          </HeaderWrapper>
          <ContentWrapper>
            <div className="content">
              <h1>You have been Logged out</h1>
              <p>Click on the login button to log in again</p>
              <br />
              <Button $variant='primary'><StyledLink to="/login">{t('welcome.app.login.link')}</StyledLink></Button>
            </div>
          </ContentWrapper>
          <BottomSection></BottomSection>

        </Wrapper>

      </FlexWrapper>
    </Background>
  )
}

export default LoggedOut

import {useEffect} from 'react';
import useTranslation from '../../../hooks/useTranslation';

function HelloWorld() {

  const {t} = useTranslation()

  //On Load
  useEffect( () => {
  }, [])
  
  //Check if translations are ready.

  return (
    <span className="HelloWorld">
      <section>
        <h1>{t('Application.title')}</h1>
        <h2>{t('Application.subTitle')}</h2>
      </section>
      <br></br>

      <footer>{t('Application.footer')}</footer>
    </span>
  );
}

export default HelloWorld;
import colors from './colors.jsx'

const color = 'indigo'
const clr = colors[`${color}`]
const darkClr = colors[`${color}Dark`]

export const base = {
    breakpoint: {
        xs: '568px',
        s: '768px',
        m: '1024px',
        l: '1200px',
        xl: '1600px',
        xxl: '2000px'
    },
    space: ['0px', '2px', '4px', '8px', '16px', '32px', '64px'],
    fonts: {
        heading: 'Inter, system-ui, sans-serif',
        body: 'Inter, system-ui, sans-serif'
    },
    fontSizes: ['12px', '14px', '16px', '20px', '24px']
}

const CrossUrl = "data:image/svg+xml xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e"

const compileTheme = (mode, color, style, navMode) => {

    let clr = colors[`${color}`]
    let navClr = colors[`${color}`]

    if (mode === 'dark') {
        clr = colors[`${color}Dark`]
    }

    let btn = `${clr[`${color}10`]}`
    let btnActive = `${clr[`${color}12`]}`
    let font = '#212529'
    let fontActive = `${clr[`${color}11`]}`
    let navFont = '#212529'
    let bg = '#fff'
    let dropdownBg = '#fff'
    let cardBg = '#fff'
    let bg1 = 'rgba(240,241,244,1)'
    let bg2 = 'rgba(245,246,249,1)'
    let bg3 = 'rgba(235,236,239,1)'
    let bg4 = 'rgba(225,226,229,1)'
    let grayBg = '#ddd'
    let fontGray = '#515559'
    let navBg = '#fff'
    let pdfBg = '#f3f3f3'
    let border = '#ced4da'
    let heavyBorder = '#a9a4aa'
    let tableBorderTop = 'rgba(217, 220, 229, 0.5)'
    let boxShadow = 'rgba(43, 45, 49, 0.1) 0px 10px 40px'
    let textControlBg = `none`
    let textControlActiveBorder = `1px solid ${clr[`${color}6`]}`
    let textControlActiveBoxShadow = ` 0 0 0 0.2rem ${clr[`${color}6`]}`
    let textControlBoxShadow = 'none'
    let tableBg = '#fff'
    let tableHeader = '#fff'
    let modalBackdrop = '#000'
    let modalShadow = 'rgba(0, 0, 0, 0.075)'
    let modalshadow_m = 'rgba(0, 0, 0, 0.15)'
    let modalBorder = 'rgba(0, 0, 0, 0.175)'
    let modalDivider = '#dee2e6'
    let paperBg = '#fff'
    let paperShadow = '0px 4px 8px rgba(38, 38, 38, 0.3)'
    let badgeBg = '#fff'
    let badgeTrue = 'var(--clr-dark-green)'
    let badgeFalse = 'var(--clr-dark-red)'


    if (mode === 'dark') {
        btnActive = `${clr[`${color}11`]}`
        font = '#eee'
        fontGray = '#b1b5b9'
        navFont = '#eee'
        bg = '#222'
        dropdownBg = '#000'
        cardBg = '#111'
        grayBg = '#333'
        bg1 = 'rgba(30,31,34,1)'
        bg2 = 'rgba(35,36,39,1)'
        bg3 = 'rgba(45,46,49,1)'
        bg4 = 'rgba(55,56,59,1)'
        navBg = '#111'
        pdfBg = navBg
        border = '#3e343a'
        heavyBorder = '#6e646a'
        tableBorderTop = 'rgba(54, 55, 57, 0.5)'
        boxShadow = 'rgba(93, 95, 99, 0.1) 0px 10px 40px'
        tableBg = '#111'
        tableHeader = '#111'

        modalBackdrop = '#fff'
        modalShadow = 'rgba(255, 255, 255, 0.075)'
        modalshadow_m = 'rgba(255, 255, 255, 0.15)'
        modalBorder = 'rgba(255, 255, 255, 0.175)'
        modalDivider = '#4e4246'

        paperBg = '#000'
        paperShadow = '0px 4px 8px rgba(217, 217, 217, 0.2)'
        badgeTrue = 'var(--clr-light-green)'
        badgeFalse = 'var(--clr-light-red)'
    }

    if (style === 'neo' && mode === 'light') {
        boxShadow = '-5px 5px 10px #a6a6a6, 5px -5px 10px #ffffff'
        tableHeader = '#ffffff'
        tableBg = 'linear-gradient(145deg, #ffffff, #dddde0)'
        cardBg = 'linear-gradient(145deg, #ffffff, #dddde0)'
        border = 'transparent'
        heavyBorder = 'transparent'
        textControlBg = `linear-gradient(145deg, #ffffff, #eeeeee)`
        textControlActiveBorder = `1px solid ${clr[`${color}10`]}`
        textControlActiveBoxShadow = 'inset 4px 4px 10px #969697, inset -4px -4px 10px #ffffff'
        textControlBoxShadow = 'inset 4px 4px 10px #b6b6b7, inset -4px -4px 10px #ffffff'
    }

    if (style === 'floating' && mode === 'light') {
        boxShadow = 'rgba(43, 45, 49, 0.6) 0px 10px 40px'
    }

    if (style === 'floating' && mode === 'dark') {
        boxShadow = 'rgba(93, 95, 99, 0.6) 0px 10px 40px'
    }


    if (style === 'neo' && mode === 'dark') {
        boxShadow = '  8px 8px 20px #131313, -8px -8px 20px #313131'
        tableHeader = '#1f1f1f'
        tableBg = 'linear-gradient(145deg, #1f1f1f, #242424)'
        cardBg = 'linear-gradient(145deg, #1f1f1f, #242424)'
        border = 'transparent'
        heavyBorder = 'transparent'
        textControlBg = `linear-gradient(145deg, #111, #292929)`
        textControlActiveBorder = `1px solid ${clr[`${color}10`]}`
        textControlActiveBoxShadow = 'inset 4px 4px 10px #131313, inset -4px -4px 10px #515151'
        textControlBoxShadow = 'inset 4px 4px 10px #131313, inset -4px -4px 10px #414141'
    }

    if (navMode === 'dark') {
        navFont = '#eee'
        navBg = '#111'
        navClr = colors[`${color}Dark`]
    }

    if (navMode === 'light') {
        navFont = '#212529'
        navBg = '#fff'
        navClr = colors[`${color}`]
    }

    if (style === 'tint') {
        bg = `${clr[`${color}1`]}`
        dropdownBg = `${clr[`${color}1`]}`
        cardBg = `${clr[`${color}1`]}`
        fontGray = `${clr[`${color}8`]}`
        bg1 = `${clr[`${color}2`]}`
        bg2 = `${clr[`${color}3`]}`
        bg3 = `${clr[`${color}4`]}`
        bg4 = `${clr[`${color}6`]}`
        navBg = `${navClr[`${color}4`]}`
        pdfBg = bg
        border = `${clr[`${color}5`]}`
        heavyBorder = `${clr[`${color}8`]}`
        tableBorderTop = `${clr[`${color}4`]}`
        textControlBg = `none`
        textControlBoxShadow = 'none'
        tableBg = `${clr[`${color}2`]}`
        tableHeader = `${clr[`${color}1`]}`
        badgeBg = tableBg
    }

    if (style === 'tint' && navMode === 'dark') {
        navClr = colors[`${color}Dark`]
        navFont = '#eee'
        navBg = `${navClr[`${color}1`]}`
    }

    if (style === 'tint' && mode !== 'dark') {
        pdfBg = `${clr[`${color}3`]}`
    }


    const base = {
        breakpoint: {
            xs: '568px',
            s: '768px',
            m: '1024px',
            l: '1200px',
            xl: '1600px',
            xxl: '2000px'
        },
        space: ['0px', '2px', '4px', '8px', '16px', '32px', '64px'],
        fonts: {
            heading: 'Inter, system-ui, sans-serif',
            body: 'Inter, system-ui, sans-serif'
        },
        fontSizes: ['12px', '14px', '16px', '20px', '24px']
    }

    const standard = {
        btn: {
            font: `#fff`,
            primary: `${btn}`,
            secondary: '#6c757d',
            danger: '#a23',
            yellow: '#e3b11a',
            grayBg: `${grayBg}`,
            active: {
                primary: `${btnActive}`,
                secondary: '#5c636a',
                danger: '#981f2d',
                yellow: '#c79910',
            }
        },
        nav: {
            background: `${navBg}`,
            active: `${navClr[`${color}3`]}`,
            stripe: `${navClr[`${color}10`]}`,
            font: `${navFont}`
        },
        area: {
            background: `linear-gradient(90deg, ${bg1} 0%, ${bg2} 3%)`,
            border: "#697588",
            highBg: `${bg4}`,
            bg: `${bg2}`,
        },
        font: {
            color: `${font}`,
            highlight: `${clr[`${color}10`]}`,
            inactive: '#ddd',
            active: `${fontActive}`,
            background: `${bg}`
        },
        dropdown: {
            background: `${dropdownBg}`,
            active: `${clr[`${color}5`]}`,
            selectedFont: `${clr[`${color}11`]}`,
            selectedBackground: `${clr[`${color}5`]}`,
            divider: 'rgba(0, 0, 0, 0.175)',
            border: 'rgba(0, 0, 0, 0.175)'
        },
        calendar: {
            selectedColor: `${bg}`,
            selectedBackground: `${clr[`${color}6`]}`
        },
        card: {
            background: `${cardBg}`,
            active: `${clr[`${color}6`]}`,
            selected: `${clr[`${color}5`]}`,
            boxShadow: `${boxShadow}`
        },
        table: {
            header: `${tableHeader}`,
            background: `${tableBg}`,
            boxShadow: `${boxShadow}`,
            shadow: 'rgba(105, 117, 136, 0.1) 0px 2px 2px',
            shadow2: 'rgb(105 117 136 / 20%) 0px 1px 3px',
            border: `${border}`,
            borderTop: `${tableBorderTop}`,
            hover: `${clr[`${color}5`]}`,
            active: `${clr[`${color}6`]}`
        },
        form: {
            background: `${cardBg}`,
            placeholder: '#ddd',
            font: `${font}`,
            border: `${border}`,
            activeBorder: `${heavyBorder}`,
            readOnly: `${fontGray}`,
            readOnlyBg: `${bg2}`,
            panelBg: `${bg3}`,
            textControl: {
                background: `${textControlBg}`,
                activeBorder: `${textControlActiveBorder}`,
                activeBoxShadow: `${textControlActiveBoxShadow}`,
                boxShadow: `${textControlBoxShadow}`
            },
            switchURL: "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e"
        },
        modal: {
            backdrop: `${modalBackdrop}`,
            background: `${bg}`,
            shadow: `${modalShadow}`,
            shadow_m: `${modalshadow_m}`,
            border: `${modalBorder}`,
            divider: `${modalDivider}`,
            closeButton: CrossUrl
        },
        tab: {
            borderColor: '#dee2e6',
            hoverBorderColor: '#e9ecef #e9ecef #dee2e6',
            activeColor: '#495057',
            activeBgColor: `${bg}`,
            activeBorderColor: '#dee2e6 #dee2e6 #fff',
            navLinkColor: `${clr[`${color}9`]}`,
            hoverColor: `${clr[`${color}7`]}`,
            disabledColor: '#6c757d'
        },
        page: {
            background: `${paperBg}`,
            shadow: `${paperShadow}`,
        },
        pdf: {
            background: `${pdfBg}`,
        },
        badge: {
            bg: `${badgeBg}`,
            true: `${badgeTrue}`,
            false: `${badgeFalse}`
        }   
    }

    return { ...standard, ...base }

}

export default compileTheme

//COLORS
export const funkyColors = {
    clr: {
        "backgroundGray": '#F5F6F9',
        "darkGray": "#697588",
        "lightGray": "#e9e9ec",
        "superLightGray": "#f2f2f6",
        "gray": "#d9dce5",
        "black": "#131c3e",
        "lightBlue": "#e4f1ff",
        "darkBlue": "#2766f1",
        "blue": "#d0e6fe",
        "lightRed": "#f7ddd9",
        "darkRed": "#ed5244",
        "lightYellow": "#fff9cc",
        "darkYellow": "#e3b11a",
        "lightGreen": "#ddf3e4",
        "darkGreen": "#078e64",
        "pink": "#ffc3ff",
        "cream": "#fbf2eb",
        "emerald": "#08423b",
    }
}
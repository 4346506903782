import React, { useState } from 'react';
//import Alert from 'react-bootstrap/Alert';
import { Button } from '../../../components/generic/Core';
//import Button from 'react-bootstrap/Button';
import { Modal as ModalBS } from 'react-bootstrap';
import Alert from '../../../components/alert/Alert';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Modal from '../../../components/modal/Modal';
import Form from 'react-bootstrap/Form';
import SwitchButton from '../../../components/generic/SwitchButton';

function MyBSModal(props) {
  return (
    <ModalBS name="BS Modal"
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen="sm-down"
      backdrop="static"
    >
      <ModalBS.Header closeButton>
        <ModalBS.Title id="contained-modal-title-vcenter">
          Modal heading
        </ModalBS.Title>
      </ModalBS.Header>
      <ModalBS.Body>
        <h4>Element Saved!</h4>
        <p>
          Congratulations, your action was a success!        </p>
      </ModalBS.Body>
      <ModalBS.Footer>
        <Button onClick={props.onHide} $variant="secondary">Close</Button>
        <Button $variant="primary">Save changes</Button>
      </ModalBS.Footer>
    </ModalBS>
  );
}

export function ModalButton() {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Button $variant="primary" onClick={() => setModalShow(true)}>
        Launch BootStrap Modal
      </Button>

      <MyBSModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export function AlertExample() {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <Alert className="error" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
        <Alert.Close onClick={() => setShow(false)}></Alert.Close>
        <p>
          Change this and that and try again. Duis mollis, est non commodo
          luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
          Cras mattis consectetur purus sit amet fermentum.
        </p>
      </Alert>
    );
  }
  return <Button onClick={() => setShow(true)}>Show Alert</Button>;
}


export function AlertSuccess() {
  const [show, setShow] = useState(true);

  return (
    <>
      {show && <Alert show={show} className="success">
        Information Saved!
      </Alert>}

      {!show && <Button onClick={() => setShow(true)}>Show Alert</Button>}
    </>
  );
}

export function SwitchExample({switchText}) {
const [checked, setChecked] = useState(true)  

  const logChange = (result) => {
    setChecked(!checked)
    console.log(result)
  }  

  return (
    <Form>
      <SwitchButton name='settingSwitch' value={checked} switchText={switchText} setValue={logChange}/>
    </Form>
  );
}

function UncontrolledExample() {
  return (
    <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Home">
        Tab content for Home
      </Tab>
      <Tab eventKey="profile" title="Profile">
        Tab content for Profile
      </Tab>
      <Tab eventKey="contact" title="Contact" disabled>
        Tab content for Contact
      </Tab>
    </Tabs>
  );
}

export default UncontrolledExample;